// choices/paletteChoices.js
import { images } from "../components/utility/imageImports";

const paletteChoices = [
  {
    label: "Μπλε",
    value: "blues",
    imageUrl: images["blues.png"],
  },
  {
    label: "Πράσινα",
    value: "greens",
    imageUrl: images["greens.png"],
  },
  {
    label: "Κίτρινα",
    value: "yellows",
    imageUrl: images["yellows.png"],
  },
  {
    label: "Κόκκινα",
    value: "reds",
    imageUrl: images["reds.png"],
  },
  {
    label: "Πορτοκαλί",
    value: "oranges",
    imageUrl: images["oranges.png"],
  },
  {
    label: "Μωβ",
    value: "violets",
    imageUrl: images["violets.png"],
  },
  {
    label: "Άσπρα",
    value: "whites",
    imageUrl: images["whites.png"],
  },
  {
    label: "Γκρι",
    value: "grays",
    imageUrl: images["grays.png"],
  },
  {
    label: "Καφέ",
    value: "browns",
    imageUrl: images["browns.png"],
  },
];

export default paletteChoices;
