// choices/lightChoices.js
const lightChoices = [
  {
    label: "Φυσικό φώς",
    value: "natural",
  },
  {
    label: "Τεχνητό φως",
    value: "artificial",
  },
  {
    label: "Μικτός φωτισμός",
    value: "mixed",
  },
];

export default lightChoices;
