const dayStartChoices = [
  {
    label: "Με έναν ήρεμο πρωινό περίπατο δίπλα στη θάλασσα.",
    value: 'With a peaceful morning walk by the sea.{"Blue":"3","Violet":"2"}',
  },
  {
    label: "Με μια ενεργητική προπόνηση.",
    value: 'With an active workout.{"Red":"3","Yellow":"2"}',
  },
  {
    label: "Με ένα ζεστό φλιτζάνι καφέ σε μια ζεστή γωνιά.",
    value:
      'With a warm cup of coffee in a cozy corner.{"Brown":"3","Green":"2"}',
  },
  {
    label: "Με μια ήσυχη στιγμή διαλογισμού σε έναν μινιμαλιστικό χώρο.",
    value:
      'With a quiet moment of meditation in a minimalist space.{"Gray":"3","Black":"2"}',
  },
];

export default dayStartChoices;
