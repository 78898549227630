import "./Loader.css";
const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Manrope",
      }}
    >
      <>
        <div className="loader"></div>
      </>

      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};
export default Loader;
