const theme = {
  font: "Manrope",
  questionsColor: "#1A1A1A",
  answersColor: "#0D7ADC",
  buttonsFontColor: "#FFFFFF",
  buttonsBgColor:
    "linear-gradient(270deg, #E53E44 0%, #9237D7 47%, #5EADD7 100%)",
  buttonsBorderRadius: 8,
  errorsFontColor: "#FFFFFF",
  errorsBgColor: "#FBE8E8",
  progressBarFillColor: "#8750D7",
  progressBarBgColor: "#F0F8FF",
};

export default theme;
