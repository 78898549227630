// choices/roomChoices.js
const roomChoices = [
  { label: "Σαλόνι 🛋️", value: "living-room" },
  { label: "Κουζίνα 🎛️", value: "kitchen" },
  { label: "Τραπεζαρία 🍴", value: "dining-room" },
  { label: "Υπνοδωμάτιο 🛏️", value: "bedroom" },
  { label: "Μπάνιο 🛁", value: "bathroom" },
  { label: "Παιδικό δωμάτιο 👶", value: "kids-room" },
  { label: "Εφηβικό δωμάτιο 👦", value: "teen-room" },
];

export default roomChoices;
