import psyImages from "../components/utility/psychologicalImageImports";
const artChoices = [
  {
    label: "Μινιμαλιστικά σχέδια.",
    value:
      'minimalist designs with neutral gray and deep, contrasting black.{"Gray":"3","Black":"2"}',
    imageUrl: psyImages["art/forFormA.jpg"],
  },
  {
    label: "Δυναμική και τολμηρή αφηρημένη τέχνη.",
    value:
      'Dynamic and bold abstract art with striking reds and cheerful yellows.{"Red":"3","Yellow":"2"}',
    imageUrl: psyImages["art/forFormB.jpg"],
  },
  {
    label: "Ειρηνική ζωγραφική τοπίων με ουρανούς, νερό και ηλιοβασιλέματα.",
    value:
      'Peaceful landscape paintings with many blue skies, water, and purple sunsets.{"Blue":"3","Violet":"2"}',
    imageUrl: psyImages["art/forFormC.jpg"],
  },
  {
    label: "Δυνατά, γεωμετρικά μοτίβα με σταθερούς τόνους.",
    value:
      'Bold, geometric patterns with steady greens and browns.{"Green":"3","Brown":"2"}',
    imageUrl: psyImages["art/forFormD.jpg"],
  },
];

export default artChoices;
