// choices/furnitureChoices.js
const furnitureChoices = [
  {
    label: "Ναι, σε ζεστούς τόνους.",
    value: "warm",
  },
  {
    label: "Ναι, σε ψυχρούς τόνους",
    value: "cold",
  },
  {
    label: "Ναι, σε ουδέτερους τόνους",
    value: "neutral",
  },
  {
    label: "Όχι, ξεκινάω από την αρχή",
    value: "start-over",
  },
];

export default furnitureChoices;
