export function adjustNumbers(numStr1, numStr2, numStr3) {
  // Convert string numbers to integers
  let num1 = parseInt(numStr1, 10);
  let num2 = parseInt(numStr2, 10);
  let num3 = parseInt(numStr3, 10);

  // Keep original values for reference
  const originalNums = [num1, num2, num3];

  // Helper function to adjust number within bounds
  const adjust = (num) => {
    if (num < 1320) return num + 1;
    return num - 1;
  };

  // Ensure all numbers are different
  while (num1 === num2 || num2 === num3 || num1 === num3) {
    if (num1 === num2) {
      num2 = adjust(num2);
    }
    if (num2 === num3) {
      num3 = adjust(num3);
    }
    if (num1 === num3) {
      num3 = adjust(num3);
    }
  }

  // Ensure they are within ±1 of their original values
  const withinBounds = (num, original) => Math.abs(num - original) <= 1;

  if (!withinBounds(num1, originalNums[0])) num1 = originalNums[0];
  if (!withinBounds(num2, originalNums[1])) num2 = originalNums[1];
  if (!withinBounds(num3, originalNums[2])) num3 = originalNums[2];

  // Ensure all numbers are different after bounds adjustment
  while (num1 === num2 || num2 === num3 || num1 === num3) {
    if (num1 === num2) {
      num2 = adjust(num2);
    }
    if (num2 === num3) {
      num3 = adjust(num3);
    }
    if (num1 === num3) {
      num3 = adjust(num3);
    }
  }

  return [num1, num2, num3].map(String); // Convert numbers back to strings
}
