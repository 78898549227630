// Function to import all images from a specified context
const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    images[item.replace("./", "")] = r(item);
    return null; // Add a return statement here to avoid linting issues
  });
  return images;
};

// Export randomStyle array
export const randomStyle = Array.from({ length: 3 }, () =>
  Math.floor(Math.random() * 2)
);

// Import images from palletes and styles directories
const palleteImages = importAll(
  require.context("../../assets/palletes", false, /\.(png|jpe?g|svg)$/)
);
const stylesImages = importAll(
  require.context("../../assets/styles", true, /\.(png|jpe?g|svg)$/)
);

function importCarousel(r) {
  return r.keys().map(r);
}

export const carouselImages = importCarousel(
  require.context("../../assets/carousel", true, /\.(png|jpe?g|svg)$/)
);

// Combine all imported images into one object and export it
export const images = { ...palleteImages, ...stylesImages };
