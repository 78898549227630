const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
    return null; // Add a return statement here
  });
  return images;
};

const psyImages = importAll(
  require.context("../../assets/psychology", true, /\.(png|jpe?g|svg)$/)
);

export default psyImages;
