// import psyImages from "../components/utility/psychologicalImageImports";
const vacationChoices = [
  {
    label: "Ένα ήσυχο καταφύγιο σε ένα μινιμαλιστικό σπα.",
    value:
      'A quiet retreat in a minimalist, gray spa with mysterious caves nearby.{"Gray":"3","Black":"2"}',
    // imageUrl: psyImages["vacation/forFormA.png"],
  },
  {
    label: "Μια γαλήνια παραλία με καταγάλανα νερά και καθαρό ουρανό.",
    value:
      'A serene beach with azure waters and twilight in the sky.{"Blue":"3","Violet":"2"}',
    // imageUrl: psyImages["vacation/forFormB.png"],
  },
  {
    label: "Ένα δάσος με ψηλά δέντρα και ορεινά μονοπάτια.",
    value:
      'A lush green forest with tall green trees and earthy brown paths.{"Green":"3","Brown":"2"}',
    // imageUrl: psyImages["vacation/forFormC.png"],
  },
  {
    label:
      "Μια πολυσύχναστη πόλη με έντονη νυχτερινή ζωή και ηλιόλουστα ανοιχτά πάρκα.",
    value:
      'A bustling city with intense nightlife and sunny, open parks.{"Red":"3","Yellow":"2"}',
    // imageUrl: psyImages["vacation/forFormD.png"],
  },
];

export default vacationChoices;
