import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import logo from "../assets/logos/vitex-logo.png";
import Form from "./Form";
import { motion, AnimatePresence } from "framer-motion";
import { carouselImages } from "./utility/imageImports";
// import overlayImg from "../assets/scrollOverlay.png";
import gsap from "gsap";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-NQHPCQNH2E"; // your Measurement ID
const LandingPage = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: "/landingpage",
      title: "Landing Page",
    });
  }, []);

  useEffect(() => {
    const wrap_up = gsap.utils.wrap(-440, 1660);
    const wrap_down = gsap.utils.wrap(3500, -900);
    const wrap_hor = gsap.utils.wrap(-620, 2380);
    // Initially colorize each box and position in a row
    gsap.set(".image-item-up", {
      y: (i) => i * 210, // Adjusted for the new image height
    });
    gsap.set(".image-item-down", {
      y: (i) => i * -440, // Adjusted for the new image height
    });
    gsap.set(".image-item-hor", {
      x: (i) => i * -300, // Adjusted for the new image width
    });

    // Animation
    gsap.to(".image-item-up", {
      duration: 35,
      ease: "none",
      y: "-=2100", // move each box 1900px up, adjusted for 10 images (10 * 190)
      modifiers: {
        y: gsap.utils.unitize(wrap_up), // force y value to wrap when it reaches -100
      },
      repeat: -1,
    });
    gsap.to(".image-item-down", {
      duration: 45,
      ease: "none",
      y: "+=4400", // move each box 1900px up, adjusted for 10 images (10 * 190)
      modifiers: {
        y: gsap.utils.unitize(wrap_down), // force y value to wrap when it reaches -100
      },
      repeat: -1,
    });
    gsap.to(".image-item-hor", {
      duration: 45,
      ease: "none",
      x: "+=3000", // move each box 1900px up, adjusted for 10 images (10 * 190)
      modifiers: {
        x: gsap.utils.unitize(wrap_hor), // force y value to wrap when it reaches -100
      },
      repeat: -1,
    });
  }, []);
  const images_up = carouselImages
    .sort(() => 0.5 - Math.random())
    .slice(10, 20);
  const images_down = carouselImages
    .sort(() => 0.5 - Math.random())
    .slice(20, 30);
  const [inView, setInView] = useState(true);
  const handleClick = () => {
    ReactGA.event({
      category: "Quiz",
      action: "Started Quiz",
      label: "Started Quiz",
    });
    setTimeout(() => {
      setInView(false);
    }, 500);
  };
  return (
    <>
      <AnimatePresence>
        {inView ? (
          <motion.div
            key={"landing-page"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="landing-page"
          >
            <div className="left-side ">
              <div className="logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="catch-phrase-container">
                <div
                  className="catch-phrase"
                  style={{ display: "inline-block" }}
                >
                  Γεια, είμαι η{" "}
                  <motion.div
                    style={{ display: "inline-block" }}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.3,
                      ease: [0, 0.71, 0.2, 1.01],
                      scale: {
                        type: "spring",
                        damping: 5,
                        stiffness: 100,
                        restDelta: 0.001,
                      },
                    }}
                    whileHover={{ scale: 1.2 }}
                    onHoverStart={(e) => {}}
                    onHoverEnd={(e) => {}}
                    className="gradient-text"
                  >
                    ΑIRIS.
                  </motion.div>
                  <br />Η <span className="gradient-text">AI</span> Color
                  Decorator της Vitex!
                  <div className="second-catch-phrase">
                    Βρίσκω τις αγαπημένες σου αποχρώσεις και τις εφαρμόζω στο
                    χώρο σου.
                  </div>
                </div>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  onHoverStart={(e) => {}}
                  onHoverEnd={(e) => {}}
                  whileTap={{ scale: 0.8 }}
                  className="button"
                  onClick={handleClick}
                >
                  Ας ξεκινήσουμε
                </motion.div>
                <div className="semi-footer ">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.5 9.35183L10.6481 12.5M6.64573 17.479C5.5067 18.6181 3.33335 18.3333 1.66669 18.3333C2.52094 16.6666 1.38191 14.4933 2.52094 13.3543C3.65997 12.2152 5.5067 12.2152 6.64573 13.3543C7.78476 14.4933 7.78476 16.34 6.64573 17.479ZM9.93469 13.2705L17.5489 5.0471C18.2196 4.32279 18.198 3.19797 17.5 2.49998C16.802 1.80199 15.6772 1.78036 14.9529 2.45101L6.72947 10.0653C6.30452 10.4587 6.09205 10.6555 5.96813 10.8653C5.67097 11.3684 5.65902 11.9904 5.93661 12.5046C6.05238 12.719 6.25713 12.9238 6.66664 13.3333C7.07615 13.7428 7.28091 13.9476 7.49534 14.0633C8.00952 14.3409 8.63153 14.329 9.13467 14.0318C9.34449 13.9079 9.54122 13.6954 9.93469 13.2705Z"
                        stroke="#333333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div>Συμπλήρωσε τη φόρμα</div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M1.66667 10C1.66667 14.6024 5.39763 18.3334 10 18.3334C11.3807 18.3334 12.5 17.2141 12.5 15.8334V15.4167C12.5 15.0297 12.5 14.8362 12.5214 14.6737C12.6691 13.5519 13.5519 12.6691 14.6737 12.5214C14.8361 12.5 15.0297 12.5 15.4167 12.5H15.8333C17.2141 12.5 18.3333 11.3807 18.3333 10C18.3333 5.39765 14.6024 1.66669 10 1.66669C5.39763 1.66669 1.66667 5.39765 1.66667 10Z"
                        stroke="#333333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.83334 10.8334C6.29358 10.8334 6.66667 10.4603 6.66667 10C6.66667 9.53978 6.29358 9.16669 5.83334 9.16669C5.3731 9.16669 5.00001 9.53978 5.00001 10C5.00001 10.4603 5.3731 10.8334 5.83334 10.8334Z"
                        stroke="#333333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.3333 7.50002C13.7936 7.50002 14.1667 7.12692 14.1667 6.66669C14.1667 6.20645 13.7936 5.83335 13.3333 5.83335C12.8731 5.83335 12.5 6.20645 12.5 6.66669C12.5 7.12692 12.8731 7.50002 13.3333 7.50002Z"
                        stroke="#333333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.33334 6.66669C8.79358 6.66669 9.16667 6.29359 9.16667 5.83335C9.16667 5.37312 8.79358 5.00002 8.33334 5.00002C7.8731 5.00002 7.50001 5.37312 7.50001 5.83335C7.50001 6.29359 7.8731 6.66669 8.33334 6.66669Z"
                        stroke="#333333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div>Επίλεξε από τις προτάσεις</div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M12.5 3.33335V1.66669M12.5 13.3334V11.6667M6.66667 7.50002H8.33333M16.6667 7.50002H18.3333M14.8333 9.83335L15.8333 10.8334M14.8333 5.16669L15.8333 4.16669M2.5 17.5L10 10M10.1667 5.16669L9.16667 4.16669"
                        stroke="#333333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div>Μεταμόρφωσε τον χώρο σου</div>
                  </div>
                </div>
              </div>
              <div className="carousel-hor">
                <div className="images-hor">
                  {images_up.map((image, index) => (
                    <div className="image-item-hor" key={index}>
                      <img src={image} alt={`Carousel-hor ${index}`} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="footer ">
                <div>Cookies Policy</div>
                <div>Privacy Policy</div>
                <div>
                  © 2024 Vitex All Rights Reserved. Designed and developed by{" "}
                  <span style={{ cursor: "pointer" }}>
                    <a
                      href="https://enneas.gr/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ENNEAS
                    </a>
                  </span>
                </div>
              </div>
              <div className="footer-mobile">
                <div>
                  <div>Cookies Policy</div>
                  <div>Privacy Policy</div>
                </div>

                <div>
                  © 2024 Vitex All Rights Reserved. Designed and developed by{" "}
                  <span style={{ cursor: "pointer" }}>
                    <a
                      href="https://enneas.gr/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ENNEAS
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="carousel-up">
                <div className="images-up">
                  {images_up.map((image, index) => (
                    <div className="image-item-up" key={index}>
                      <img src={image} alt={`Carousel-up ${index}`} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="carousel-down">
                <div className="images-down">
                  {images_down.map((image, index) => (
                    <div className="image-item-down" key={index}>
                      <img src={image} alt={`Carousel-down ${index}`} />
                    </div>
                  ))}
                </div>
              </div>
              {/* <motion.img
                whileHover={{ scale: 1.2 }}
                onHoverStart={(e) => {}}
                onHoverEnd={(e) => {}}
                className="overlay-img"
                src={overlayImg}
                alt="overlay"
              /> */}
            </div>
          </motion.div>
        ) : (
          <Form />
        )}
      </AnimatePresence>
    </>
  );
};

export default LandingPage;
