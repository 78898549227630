import roomChoices from "../../formChoices/roomChoices";
import styleChoices from "../../formChoices/styleChoices";
import lightChoices from "../../formChoices/lightChoices";
import furnitureChoices from "../../formChoices/furnitureChoices";
import vibeChoices from "../../formChoices/vibeChoices";
import endChoices from "../../formChoices/endChoices";
import paletteChoices from "../../formChoices/paletteChoices";
import vacationChoices from "../../formChoices/vacationChoices";
import artChoices from "../../formChoices/artChoices";
import weatherChoices from "../../formChoices/weatherChoices";
import bookChoices from "../../formChoices/bookChoices";
import sceneryChoices from "../../formChoices/sceneryChoices";
import gardenChoices from "../../formChoices/gardenChoices";
import dayStartChoices from "../../formChoices/dayStartChoices";

export const answersMap = (formData) => {
  let data = {};
  //For each question, log the data
  Object.keys(formData).forEach((key) => {
    if (formData[key].value !== undefined) {
      data[key] = formData[key].value;
      // console.log(key, formData[key].value);
    }
  });
  return data;
};

export const constructUrl = (answers) => {
  console.log("Answers are", answers);
  const choiceMap = {
    room: roomChoices,
    style: styleChoices,
    light: lightChoices,
    furniture: furnitureChoices,
    vibe: vibeChoices,
    end: endChoices,
    pallete: paletteChoices,
    vacation: vacationChoices,
    art: artChoices,
    weather: weatherChoices,
    book: bookChoices,
    scenery: sceneryChoices,
    garden: gardenChoices,
    dayStart: dayStartChoices,
  };

  const answerMap = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
    4: "E",
    5: "F",
    6: "G",
    7: "H",
    8: "I",
    9: "J",
  };

  let resultString = "";

  Object.keys(answers)
    .sort()
    .forEach((key) => {
      console.log("Checking for key", key);
      if (key === "formId" || key === "end") {
        return;
      }
      if (choiceMap[key]) {
        //Take all the .value from the choicesMap[key] and put them in an array
        let choices = choiceMap[key];
        if (typeof choices === "function") {
          choices = choices();
        }
        console.log("Choices are", choices);
        console.log("Answers are", answers[key]);
        answers[key].forEach((answerValue) => {
          console.log("Checking for answer value", answerValue);
          const choiceIndex = choices.findIndex(
            (choice) => choice.value === answerValue
          );
          if (choiceIndex !== -1 && answerMap[choiceIndex] !== undefined) {
            resultString += answerMap[choiceIndex];
            console.log("This is the result string so far", resultString);
          }
        });
      }
      resultString += "-"; // Separate different categories with a dash
    });

  // Remove the last dash if present
  if (resultString.endsWith("-")) {
    resultString = resultString.slice(0, -1);
  }

  return resultString;
};
