const sceneryChoices = [
  {
    label: "Μια τραχιά οροσειρά με βράχους και γκρίζο ουρανό.",
    value:
      'A rugged mountain range with dark rocks and a gray sky.{"Gray":"3","Black":"2"}',
  },
  {
    label: "Ένα πολύχρωμο λιβάδι σε πλήρη άνθιση με λουλούδια και παπαρούνες.",
    value:
      'A colorful meadow in full bloom with yellow flowers and red poppies.{"Red":"3","Yellow":"2"}',
  },
  {
    label: "Μια γαλήνια όχθη λίμνης με γαλάζια νερά.",
    value:
      'A tranquil lakeside with blue waters and violet twilight.{"Blue":"3","Violet":"2"}',
  },
  {
    label: "Ένα πυκνό δάσος με χωμάτινα μονοπάτια.",
    value:
      'A dense forest with green foliage and brown earthy paths.{"Green":"3","Brown":"2"}',
  },
];

export default sceneryChoices;
