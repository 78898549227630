const bookChoices = [
  {
    label: "Συναρπαστικές περιπέτειες και ανάλαφρες ευχάριστες ιστορίες.",
    value:
      'Exciting adventures and light enjoyable stories.{"Red":"3","Yellow":"2"}',
  },
  {
    label: "Σκοτεινές ιστορίες και φιλοσοφικά στοχαστικά έργα.",
    value:
      'Mysteries and dark stories and philosophical, contemplative works.{"Black":"3","Gray":"2"}',
  },
  {
    label: "Ιστορίες που εμπνέουν και συγκινητικές οικογενειακές αφηγήσεις.",
    value:
      'Inspiring stories of resilience and moving family narratives.{"Green":"3","Brown":"2"}',
  },
  {
    label: "Μυθιστορήματα και ιστορίες φαντασίας με μαγικά στοιχεία.",
    value:
      'Deep, contemplative novels and fantasy stories with magical elements.{"Blue":"3","Violet":"2"}',
  },
];

export default bookChoices;
