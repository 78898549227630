// choices/vibeChoices.js
const vibeChoices = [
  {
    label: "Ηρεμία",
    value: "calm",
  },
  {
    label: "Ενεργητικότητα",
    value: "active",
  },
  {
    label: "Άνεση",
    value: "comfortable",
  },
  {
    label: "Κομψότητα",
    value: "elegant",
  },
  {
    label: "Δημιουργικότητα",
    value: "creative",
  },
];

export default vibeChoices;
