// choices/endChoices.js
const endChoices = [
  {
    label: "Ανακάλυψε τα χρώματά σου",
    value: "results",
  },
  {
    label: "Ας τις εξειδικεύσουμε κι άλλο",
    value: "large",
  },
];

export default endChoices;
