import psyImages from "../components/utility/psychologicalImageImports";
const gardenChoices = [
  {
    label: "Ένας κήπος ζεν με μινιμαλιστικές νότες.",
    value:
      'A zen garden with minimalist gray stones and black sand.{"Gray":"3","Black":"2"}',
    imageUrl: psyImages["garden/forFormA.jpg"],
  },
  {
    label: "Ένας ζωντανός ανθόκηπος με τριαντάφυλλα και ηλιοτρόπια.",
    value:
      'A vibrant flower garden with red roses and yellow sunflowers.{"Red":"3","Yellow":"2"}',
    imageUrl: psyImages["garden/forFormB.jpg"],
  },
  {
    label: "Ένας ειρηνικός κήπος με νερό, κρίνα και ίριδες.",
    value:
      'A peaceful garden with water, blue lilies, and purple irises.{"Blue":"3","Violet":"2"}',
    imageUrl: psyImages["garden/forFormC.jpg"],
  },
  {
    label: "Ένας πλούσιος λαχανόκηπος.",
    value:
      'A lush vegetable garden with green plants and earthy brown soil.{"Green":"3","Brown":"2"}',
    imageUrl: psyImages["garden/forFormD.jpg"],
  },
];

export default gardenChoices;
