// choices/styleChoices.js
import { images } from "../components/utility/imageImports";

const styleChoices = (room) => [
  {
    label: "Βιομηχανικό",
    value: "industrial",
    imageUrl: images[`industrial/${room}/forForm.jpg`],
  },
  {
    label: "Σκανδιναβικό",
    value: "scandinavian",
    imageUrl: images[`scandinavian/${room}/forForm.jpg`],
  },
  {
    label: "Μεσογειακό",
    value: "mediterranean",
    imageUrl: images[`mediterranean/${room}/forForm.jpg`],
  },
  {
    label: "Κυκλαδίτικο",
    value: "cycladic",
    imageUrl: images[`cycladic/${room}/forForm.jpg`],
  },
  {
    label: "Νεοκλασικό",
    value: "neoclassical",
    imageUrl: images[`neoclassical/${room}/forForm.jpg`],
  },
  {
    label: "Minimal",
    value: "minimal",
    imageUrl: images[`minimal/${room}/forForm.jpg`],
  },
  // {
  //   label: "Παραδοσιακό/Ρουστίκ",
  //   value: "traditional",
  //   imageUrl: images[`traditional/${room}/forForm.jpg`],
  // },
  {
    label: "Boho",
    value: "boho",
    imageUrl: images[`boho/${room}/forForm.jpg`],
  },
  {
    label: "Vintage/Ρετρό",
    value: "vintage",
    imageUrl: images[`vintage/${room}/forForm.jpg`],
  },
  {
    label: "Pop",
    value: "pop",
    imageUrl: images[`pop/${room}/forForm.jpg`],
  },
];

export default styleChoices;
