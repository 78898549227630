const weatherChoices = [
  {
    label:
      "Σταθερός, ήπιος καιρός με καταπράσινα τοπία και φυσικές αποχρώσεις.",
    value:
      'Steady, mild weather with green landscapes and earthy brown tones.{"Green":"3","Brown":"2"}',
  },
  {
    label: "Συννεφιασμένες ημέρες με μυστηριώδη σύννεφα.",
    value:
      'Cloudy days with introspective gray and mysterious black clouds.{"Gray":"3","Black":"2"}',
  },
  {
    label: "Ήρεμες ημέρες με καθαρό ουρανό και μαγευτικά βράδια.",
    value:
      'Calm, clear days with blue skies and magical violet evenings.{"Blue":"3","Violet":"2"}',
  },
  {
    label:
      "Ηλιόλουστες ημέρες με έντονες εκλάμψεις και εκπληκτικά ηλιοβασιλέματα.",
    value:
      'Hot, sunny days with bursts of yellow and energetic storms.{"Red":"3","Yellow":"2"}',
  },
];

export default weatherChoices;
